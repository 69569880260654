import AddDiscountComponent from './add-discount-component';

import { Divider, } from 'antd';
import { Modal, Select, Skeleton } from '@mantine/core';
import { useState } from 'react';
import LineItemsComponent from './bill-items-component';
import { calculatePaystackAmount, cedisLocale, configurePaystack } from '../../helpers/utilities';
import { useAtom } from 'jotai';
import {
    deleteItemAtom, getCompleteBillAtom, getBillSubTotalAtom,
    getTotalPaymentsAtom, resetBillAtom,
    billAtom, billItemsAtom, updateItemAtom,
    addItemAtom,
    servicesAtom,
    paymentsAtom
} from '../../helpers/state/bill';
import { useQuery } from 'react-query';
import _ from 'lodash';
import { getAllPatients, getAllServices } from '../../helpers/api';

import NewPatientForm from '../../pages/catalog/components/new-patient';
import AddNoteComponent from './add-note-component';
import CheckOutComponent from './checkout';
import BillReceipt from './receipt';
import { usePaystackPayment } from 'react-paystack';
import { getUser } from '../../helpers/auth';


const NewBillForm = ( { onSuccess } ) => {

    // hooks
    const [ isCheckOut, setIsCheckOut ] = useState( false );

    // atoms
    const [ bill, setBill ] = useAtom( billAtom );
    const [ services, setServices ] = useAtom( servicesAtom );
    const [ selectedItems ] = useAtom( billItemsAtom );
    const [ , deleteItem ] = useAtom( deleteItemAtom );
    const [ , addItem ] = useAtom( addItemAtom );
    const [ , updateItem ] = useAtom( updateItemAtom );
    const [ , resetBill ] = useAtom( resetBillAtom );
    const [ subTotal ] = useAtom( getBillSubTotalAtom );
    const [ completeBill ] = useAtom( getCompleteBillAtom );
    const [ totalPayments ] = useAtom( getTotalPaymentsAtom );
    const [ payments ] = useAtom( paymentsAtom );

    const totalDue = parseFloat( subTotal - ( bill.discount || 0 ) );
    const balance = parseFloat( totalDue - totalPayments );

    // queries
    const { data: patients, isFetching: fetchingPatients, refetch: fetchPatients } = useQuery( {
        queryFn: getAllPatients,
        queryKey: [ 'patients' ],
    } );

    const { isFetching: fetchingServices, refetch: fetchServices } = useQuery( {
        queryFn: getAllServices,
        queryKey: [ 'services' ],
        onSuccess: data => setServices( data )
    } );

    // others
    const [ modal, setModal ] = useState( {
        isOpen: false,
        title: '',
        content: null,
        size: "",
        zIndex: 60
    } );



    const initializePayment =
        usePaystackPayment( configurePaystack(
            calculatePaystackAmount( payments ),
            patients?.find( pa => pa.id === bill.patientId ),
            {
                userId: getUser().id,
                facility: getUser().facility?.facilityName,
                branch: getUser().branches?.find( br => br.id === getUser()?.branchId )?.branchName,
                username: getUser().staff?.name,
            }
        ) );


    return (
        <div className='row sticky-bottom'>
            <Modal
                onClose={ () => setModal( { ...modal, isOpen: false } ) }
                opened={ modal.isOpen }
                title={ modal.title }
                size={ modal.size || 'md' }
                zIndex={ 55 }
            >
                { modal.content }
            </Modal>

            <div className="col-md-8 col-12">
                <div className="row">
                    <div className="col-5 fw-bold">Service Name</div>
                    <div className="col-2 fw-bold">Rate</div>
                    <div className="col-2 fw-bold">Quantity</div>
                    <div className="col-3 fw-bold">Line Total</div>
                </div>
                <Divider className='mb-0' />
                {
                    selectedItems?.length > 0 &&
                    <table className='table stripped table-hover'>
                        <tbody>
                            {
                                selectedItems.map( ( item, i ) =>
                                    <tr>
                                        <LineItemsComponent
                                            item={ item }
                                            services={ services }
                                            onUpdate={ updateItem }
                                            onDelete={ deleteItem } />
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                }
                <div className="mt-3">
                    <button onClick={ () => addItem() } className={ `button app-btn btn-prim fw-bold ${ fetchingServices && ' is-loading' }` }>
                        <span className='bi bi-plus-circle me-2' />
                        Add Bill Item
                    </button>
                    {
                        ( selectedItems.length > 0 ) &&
                        <button
                            className='button app-btn is-ghost px-2 fw-bold ms-2'
                            onClick={ () => resetBill() }
                        >
                            <span className="bi bi-trash me-2"></span>
                            clear
                        </button>
                    }
                </div>
            </div>
            {
                isCheckOut ?
                    <div className="col-md-4 col-12 mt-5 mt-md-0">
                        <button
                            onClick={ () => setIsCheckOut( false ) }
                            className='mb-2 button app-btn btn-prim fw-bold'>
                            <span className="bi bi-arrow-left-circle me-2" />
                            back
                        </button>
                        <CheckOutComponent
                            onUpdate={ ( data ) => {
                                onSuccess();
                                setModal( {
                                    title: 'Receipt',
                                    isOpen: true,
                                    content: <BillReceipt param='billId' query={ data.id } />,
                                    size: 300,
                                    zIndex: 100
                                } );
                            } }
                        />
                    </div> :
                    <div className="col-md-4 col-12 mt-5 mt-md-0">
                        {/* customer selection section */ }
                        <div className="field mb-0">
                            <label htmlFor="patientId">Patient / Client</label>
                            {
                                fetchingPatients ?
                                    <Skeleton visible={ fetchingPatients } color='blue' /> :
                                    <Select
                                        id='patientId'
                                        value={ bill.patientId }
                                        nothingFound="No match"
                                        onChange={ ( value ) => setBill( { patientId: value } ) }
                                        size="md"
                                        clearable
                                        searchable
                                        placeholder='select patient / client'
                                        data={
                                            patients?.map( pa => {
                                                return {
                                                    label: `${ pa.firstName } ${ pa.lastName }`,
                                                    value: pa.id
                                                };
                                            } )
                                        }
                                    />
                            }
                        </div>
                        <p
                            className='text-muted mt-1 hover-hand'
                            onClick={ () => setModal( {
                                isOpen: true,
                                title: 'Add Client/Patient',
                                content: <NewPatientForm
                                    showCharm={ false }
                                    showBottomButtons
                                    onSuccess={ fetchPatients }
                                />,
                            } ) }
                        >
                            <span className="bi bi-plus-circle me-2"></span>
                            add patient / client
                        </p>
                        <Divider />
                        {/* add more */ }
                        {
                            selectedItems.length > 0 &&
                            <>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <strong>ADD</strong>
                                    </div>
                                    <div>
                                        <button className='button app-btn fw-bold is-ghost'
                                            title='add a discount'
                                            onClick={ () => setModal( {
                                                isOpen: true,
                                                title: 'Update Discount',
                                                content: <AddDiscountComponent
                                                />
                                            } ) }
                                        >
                                            {
                                                bill.discount > 0 &&
                                                <span className="bi bi-info-circle-fill text-success me-1"></span>
                                            }
                                            Discount</button>
                                        <button className='button app-btn fw-bold is-ghost'
                                            title='add notes to this bill'
                                            onClick={ () => setModal( {
                                                isOpen: true,
                                                title: 'Update Notes',
                                                content: <AddNoteComponent />
                                            } ) }
                                        >
                                            {
                                                bill.note &&
                                                <span className="bi bi-info-circle-fill text-success me-1"></span>
                                            }
                                            Note
                                        </button>
                                    </div>
                                </div>
                                <Divider className='my-2' />
                            </>
                        }
                        {
                            bill.discount > 0 &&
                            <div className='d-flex justify-content-between mb-2 fw-bold'>
                                <span>Discount { `(${ ( ( bill.discount / subTotal ) * 100 ).toPrecision( 2 ) }%)` }</span>
                                <span>{ cedisLocale.format( bill.discount ) }</span>
                            </div>
                        }
                        <div className='d-flex justify-content-between'>
                            <strong>Subtotal</strong>
                            <strong>{ cedisLocale.format( subTotal ) }</strong>
                        </div>
                        {
                            bill?.discount > 0 &&
                            <>
                                <Divider />
                                <div className='d-flex justify-content-between'>
                                    <strong>Total Due:</strong>
                                    <strong>{ cedisLocale.format( totalDue ) }</strong>
                                </div>
                            </>
                        }
                        <div className='d-flex justify-content-between my-2'>
                            <strong>Paid</strong>
                            <strong>{ cedisLocale.format( totalPayments ) }</strong>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <strong>Balance</strong>
                            <h5 className={ `fw-bold ${ balance != 0 ? ' text-danger' : ' text-success' }` }>GHS { cedisLocale.format( balance ) }</h5>
                        </div>
                        {/* <Divider className='my-2' />
                    <div>
                        <strong>Tax</strong>
                    </div> */}
                        <Divider />
                        <div className="buttons has-addons">
                            <button
                                disabled={ selectedItems.length < 1 }
                                onClick={ () => setIsCheckOut( true ) }
                                // onClick={ () => setModal( {
                                //     title: 'Check Out',
                                //     isOpen: true,
                                //     content: <CheckOutComponent
                                //         onUpdate={ ( data ) => {
                                //             setModal( { isOpen: false } );
                                //             onSuccess();
                                //             setModal( {
                                //                 title: 'Receipt',
                                //                 isOpen: true,
                                //                 content: <BillReceipt param='billId' query={ data.id } />,
                                //                 size: 300,
                                //                 zIndex: 100
                                //             } );
                                //         } }
                                //     />,
                                // } ) }
                                className={ `button h6 py-4 w-75 app-btn ${ totalPayments > 0 && ' btn-prim ' } d-flex justify-content-between` }>
                                <div className='fw-bold'>
                                    <span className="bi bi-check-circle me-2" />
                                    {/* { totalPayments > 0 ? 'CHECKOUT ' : 'N/A' } */ }
                                    CHECK OUT
                                </div>
                                <div className='ms-2'>{ selectedItems?.length === 1 ? '1 item' : `${ selectedItems?.length || 0 } items` }</div>
                                {/* <div>GHS { cedisLocale.format( totalPayments > 0 ? totalPayments : totalDue ) }</div> */ }
                            </button>
                            <button
                                disabled={
                                    payments?.filter( pay =>
                                        pay.paymentMethod === 'momo' ||
                                        pay.paymentMethod === 'card'
                                    ).length < 1
                                }
                                onClick={ () => initializePayment( { onSuccess: reference => null } ) }
                                className='button h5 w-25 py-4 app-btn'>
                                <span className="bi bi-credit-card-2-front me-2" />
                                Pay
                            </button>
                        </div>
                    </div > }
        </div>
    );
};

export default NewBillForm;