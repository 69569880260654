import { message } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useMutation } from "react-query";
import { postNewDiagnosis, postNewDrug, postNewLabActivity } from "../../helpers/api";
import { RequiredIndicator } from "../presentation-components";

export const NewDrugForm = ( { onSuccess } ) => {
    const [ state, setState ] = useState( { drugName: '' } );


    const { mutateAsync, isLoading } = useMutation( ( data ) => postNewDrug( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 201 ) {
                message.success( data.data.message );
                onSuccess();
                setState( { drugName: '' } );
                return;
            }

            throw data;
        },

        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );

    const handleSubmit = () => {
        if ( _.isEmpty( state.drugName ) ) {
            message.error( 'drug name missing' );
            return;
        }

        mutateAsync( state );
    };


    return (
        <div>
            <div className="field">
                <label className="mb-0" htmlFor="title">
                    Drug Name
                    <RequiredIndicator />
                </label>
                <input
                    value={ state.drugName }
                    required
                    onChange={ e => setState( { drugName: e.target.value } ) }
                    className="input" type="text"
                    autoFocus
                    id="title" placeholder="enter drug name" />
            </div>
            <button
                onClick={ handleSubmit }
                disabled={ state.drugName.length === 0 }
                className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading' }` } >
                <span className="bi bi-save me-2"></span>
                Add Drug
            </button>
        </div>
    );
};


export const NewDiagnosisForm = ( { onSuccess } ) => {
    const [ state, setState ] = useState( { code: '', title: '' } );


    const { mutateAsync, isLoading } = useMutation( ( data ) => postNewDiagnosis( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 201 ) {
                message.success( data.data.message );
                onSuccess();
                setState( { code: '', title: '' } );
                return;
            }

            throw data;
        },

        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );

    const handleSubmit = () => {
        if ( _.isEmpty( state.code || state.title ) ) {
            message.error( 'required fields missing' );
            return;
        }

        mutateAsync( state );
    };


    return (
        <div>
            <div className="field">
                <label className="mb-0" htmlFor="title">
                    Code
                    <RequiredIndicator />
                </label>
                <input
                    value={ state.code }
                    required
                    maxLength={ 4 }
                    onChange={ e => setState( { ...state, code: e.target.value } ) }
                    className="input" type="text"
                    autoFocus
                    id="title" placeholder="diagnosis code" />
            </div>
            <div className="field">
                <label className="mb-0" htmlFor="title">
                    Diagnosis Name (Title)
                    <RequiredIndicator />
                </label>
                <input
                    value={ state.title }
                    required
                    onChange={ e => setState( { ...state, title: e.target.value } ) }
                    className="input" type="text"
                    autoFocus
                    id="title" placeholder="diagnosis title" />
            </div>
            <button
                onClick={ handleSubmit }
                className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading' }` } >
                <span className="bi bi-save me-2"></span>
                Add Diagnosis
            </button>
        </div>
    );
};




export const NewLabTestForm = ( { onSuccess } ) => {
    const [ state, setState ] = useState( { description: '' } );


    const { mutateAsync, isLoading } = useMutation( ( data ) => postNewLabActivity( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 201 ) {
                message.success( data.data.message );
                onSuccess();
                setState( { description: '' } );
                return;
            }

            throw data;
        },

        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );

    const handleSubmit = () => {
        if ( _.isEmpty( state.description ) ) {
            message.error( 'required field missing' );
            return;
        }

        mutateAsync( state );
    };


    return (
        <div>
            <div className="field">
                <label className="mb-0" htmlFor="title">
                    Title
                    <RequiredIndicator />
                </label>
                <input
                    value={ state.description }
                    required
                    onChange={ e => setState( { description: e.target.value } ) }
                    className="input" type="text"
                    autoFocus
                    id="title" placeholder="lab test name" />
            </div>
            <button
                onClick={ handleSubmit }
                className={ `button h6 app-btn btn-prim mt-3 ${ isLoading && ' is-loading' }` } >
                <span className="bi bi-save me-2"></span>
                Add Lab Test
            </button>
        </div>
    );
};