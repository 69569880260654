import * as XLSX from "xlsx";
import { message, notification } from "antd";
import {
  differenceInMonths,
  getMonth,
  getYear,
} from "date-fns";
import { urls } from "./config";
import { postQuery } from "./api";
import { nanoid } from 'nanoid';
import validator from 'validator';
import { rbac } from './roles';
import { getRole, resouces, roles } from "./auth";

import osLogo from '../static/img/riviaos-icon.png';
import _ from "lodash";

const CryptoJS = require( "crypto-js" );

export const appUrl = urls.app_url;
export const cedisLocale = Intl.NumberFormat( "en-GH" );
export const dateFormat = "ddd, MMMM D yyy";

// 
export const uniqueCode = nanoid();

export const toTitleCase = ( word ) => {
  let firstChar = word.slice( 0, 1 ),
    remainder = word.slice( 1 );
  return `${ firstChar.toUpperCase() }${ remainder.toLowerCase() }`;
};

export const generateFileUrl = ( fileName, isImage = true ) => {
  if ( !fileName ) return null;

  let url = "";

  url = appUrl.replace( "api", "static" );
  url = `${ url }/${ fileName }`;

  return url;
};

export const uploadFile = async ( url, file, fileName ) => {
  const formData = new FormData();
  formData.append( fileName, file );

  try {
    const res = await postQuery( url, formData );
    if ( res.status === 200 ) return res;
    throw new Error( "file upload failed" );
  } catch ( ex ) {
    console.log( "error " );
  }
};

export function getRandomNumberBetween ( min, max ) {
  return Math.floor( Math.random() * ( max - min + 1 ) + min );
}

export function getBase64 ( img, callback ) {
  const reader = new FileReader();
  reader.addEventListener( "load", () => callback( reader.result ) );
  reader.readAsDataURL( img );
}

export function beforeUpload ( file ) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if ( !isJpgOrPng ) {
    message.error( "You can only upload JPG/PNG file!" );
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if ( !isLt2M ) {
    message.error( "Image must smaller than 2MB!" );
  }
  return isJpgOrPng && isLt2M;
}

export const openNotification = (
  title,
  msg,
  type = "info" | "error" | "warning" | "success",
  className = "",
  btn,
  duration = 5
) => {
  // type
  const key = `open${ Date.now() }`;
  notification[ type ]( {
    message: title,
    description: msg,
    btn,
    key,
    placement: "top",
    className: "mt-5 " + className,
    duration,
  } );
};

export const encryptString = ( string, key ) => {
  // Encrypt
  const encrypted = CryptoJS.AES.encrypt( string, key ).toString();
  return encrypted;
};

export const decryptString = ( string, key ) => {
  // Decrypt
  const decrypted = CryptoJS.AES.decrypt( string, key );
  return decrypted.toString( CryptoJS.enc.Utf8 );
};

export const toUrl = ( text ) => {
  if ( text === undefined || text.length === 0 || text === "" ) return;
  return text.trim().toLowerCase().replace( " ", "-" );
};

export const calcAge = ( date ) => {
  const thisYear = getYear( new Date() );
  const yearFromDate = getYear( new Date( date ) );
  // let age = parseInt(thisYear - yearFromDate);
  // let age = differenceInYears( thisYear, yearFromDate );
  let age = thisYear - yearFromDate;
  let interval = "years";

  if ( age < 1 ) {
    const thisMonth = getMonth( new Date() );
    const monthFromDate = getMonth( new Date( date ) );
    // age = parseInt(thisMonth - monthFromDate);
    age = differenceInMonths( monthFromDate, thisMonth );
    interval = "months";
  }

  return { age, interval };
};

export const extractInputData = ( e ) => {
  try {
    e.preventDefault();
    const data = {};
    Object.values( e.target ).map( ( ip ) => {
      // look for input nodes with name properties
      if ( ip.nodeName === "INPUT" && ip.name ) data[ ip.name ] = ip.value;
    } );
    return data;
  } catch ( ex ) {
    openNotification( "Extract Error", ex, "error" );
    console.log( "error", ex );
  }
};

export const exportToExcel = (
  tableId,
  fileName = "Rivia " + fileName,
  sheetName = "sheet1"
) => {
  try {
    var elt = document.getElementById( tableId );
    var wb = XLSX.utils.table_to_book( elt, { sheet: sheetName } );
    return XLSX.writeFile( wb, `${ fileName }.xlsx` );
  } catch ( ex ) {
    openNotification(
      "Export Error",
      "there was an error exporting to excel",
      "error"
    );
    console.log( ex );
  }
};

/**
 * returns a url formatted string from an array of strings
 * @param {string[]} paths list of strings to convert into a url
 * @return {string}
 */
export const generateRoute = ( paths ) => {
  let path = "";
  paths.map( ( p ) => {
    path += "/" + p.trim().replace( "/", "" );
  } );

  return path;
};

/**
 * takes a an ant-design drop down value and set it to a designated state
 * @param {string} fieldName name of dropdown field
 * @param {string} value value from dropdown field
 * @param {any} state the current state that will be modified
 * @param {void} setter the state setter method
 * @return {void}
 */
export const dropDownSet = ( fieldName, value, state, setter ) => {
  setter( {
    ...state,
    [ fieldName ]: value,
  } );
};

/**
 * a little utility that sets a state hook based on the state setter and the provided data
 * @param {void} stateSetter the state setter method
 * @param {any} newState the new state that will be applied to the state hook
 * @return {void}
 */
export const setState = ( stateSetter, newState ) => {
  stateSetter( newState );
};


/**
 * a little utility that filters an array by date and return the filtered records
 * @param {array} data the list of record
 * @param {string} dateRecord the column / field name
 * @param {date} date the date to filtered against. leave blank to current date
 * @return {array}
 */
export const dateFilter = ( data, dateRecord, date = new Date() ) => {
  if ( data.length === 0 )
    return data;

  const newList = data.filter( record => record[ dateRecord ] );
};


/**
 * 
 * @param {string} text the text to render
 * @param {string} length the cut off length (length of allowable text string. pad with dots afterwards)
 * @returns string
 */
export const ellipse = ( text, length = 5 ) => {
  length = parseInt( length );

  if ( text.length < length )
    return text.slice( 0, length - 1 ) + "...";
  return text;
};



/**
 * a little utility function to copy text to the clip board
 * @param {string} text the text / data to copy to the clipboard
 * @return void
 */
export const copyToClipboard = async ( text ) => {
  return await navigator.clipboard.writeText( text );
};



/**
 * returns a specific background color based on the calculated percentage complete
 * @param {number} completedTasks 
 * @param {number} totalTasks 
 * @returns {string}
 */
export const consultationColorCoding = ( completedTasks, totalTasks ) => {
  const percentage = parseFloat( ( ( completedTasks || 0 ) / ( totalTasks || 0 ) ) * 100 ).toFixed( 2 );
  const colorCoding = `
            ${ percentage <= 10 && 'bg-danger' }
            ${ ( percentage > 10 && percentage <= 49 ) && 'bg-warning' }
            ${ ( percentage > 49 && percentage <= 89 ) && 'bg-info' }
            ${ ( percentage > 89 ) && 'bg-success' }
            `;

  return colorCoding;
};

/**
 * 
 * @param {number} numberOfDays number of days to look back at
 * @returns {array}
 */
export const getPreviousDates = ( numberOfDays ) => {

  const days = parseInt( numberOfDays );
  if ( typeof days !== 'number' )
    return 1;

  let dates = [], counter = 1;

  if ( numberOfDays === 1 )
    dates.push( new Date() );

  else
    while ( counter !== numberOfDays ) {

      dates.push(
        new Date()
      );
      ++counter;
    }

  return dates;
};


/**
 * takes two numbers and returns the percentage difference between them
 * @param {number} left first number
 * @param {number} right second number
 * @returns number
 */
export const percentage = ( left, right ) => {
  if ( left === 0 && right === 0 )
    return 0;

  return ( ( left / right ) * 100 );
  // return percentage;
};



/**
 * Returns a numeric value denoting the password strength
 * @param {string} password the password string
 * @returns {number}
 */
export const passwordStrength = ( password ) => {
  return validator.isStrongPassword( password, {
    minLength: 6,
    minLowercase: 1,
    minSymbols: 1,
    minUppercase: 1,
    pointsForContainingLower: 5,
    pointsForContainingSymbol: 10,
    pointsForContainingUpper: 5,
    pointsForContainingNumber: 5,
    pointsPerUnique: 5,
    // pointsPerRepeat: 0,
    returnScore: true
  } );
};



export const hasPermission = ( resouce, action ) => {
  try {
    if ( rbac[ roles[ getRole() ] ][ resouces[ resouce ] ][ action ] )
      return true;
    else
      return false;
  } catch ( error ) {
    return false;
  }
};



export const updateData = ( state, setState, fieldName, data ) => {
  return setState( { ...state, [ fieldName ]: data } );
};

// weight in kilograms and height in meters
export const calcBMI = ( weight, height ) =>
  parseFloat( ( parseFloat( weight || 0 ) / parseFloat( ( height * height ) || 0 ) ).toFixed( 2 ) );


/**
 * sends a native desktop notification
 * @param {string} message the notification message
 * @param {string} title the notification title
 * @param {any} data the data to send along with the notification
 * @param {void} onClickAction the action to perform when the notification is clicked
 * @param {void} onCloseAction the action to perform when the notification is closed
 */
export const appNotification = ( title, message, data, onClickAction, onCloseAction ) => {
  if ( !( "Notification" in window ) ) {
    // Check if the browser supports notifications
    alert( "This browser does not support desktop notification" );
  } else if ( Notification.permission === "granted" ) {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    const notification = new Notification( title, {
      body: message,
      icon: osLogo,
      badge: osLogo,
      data,
    } );

    notification.onclick = onClickAction;
    notification.onclose = onCloseAction;
    // …
  } else if ( Notification.permission !== "denied" ) {
    // We need to ask the user for permission
    Notification.requestPermission().then( ( permission ) => {
      // If the user accepts, let's create a notification
      if ( permission === "granted" ) {
        const notification = new Notification( title, {
          body: message,
          icon: osLogo,
          badge: osLogo,
          data,
        } );

        notification.onclick = onClickAction;
        notification.onclose = onCloseAction;

      }
    } );
    // alert( 'For enhanced experience, accept notifications' );
  }

};


export const getDatesBetween = ( startDate, endDate ) => {
  const dates = [];

  // Strip hours minutes seconds etc.
  let currentDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );

  while ( currentDate <= endDate ) {
    dates.push( currentDate );

    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1, // Will increase month if over range
    );
  }

  return dates;
};


export const dateCounter = ( dates = [], dateField ) => {
  let cleanedDates = [];

  dates?.map( dt => {
    let formattedDate = new Date( dt[ dateField ] ).toDateString();
    const fndDate = cleanedDates.find( cd => new Date( cd.date ).toDateString() == formattedDate );

    if ( fndDate )
      cleanedDates.map( cd => {
        if ( cd.date === formattedDate ) {
          cd.count++;
          return cd;
        }
        else
          return cd;
      } );
    else
      cleanedDates.push( {
        date: formattedDate,
        count: 1
      } );
  } );

  return cleanedDates;
};


export const getAppointmentStatusColor = ( status ) => {

  let color = '';
  switch ( status ) {
    case 'scheduled':
      color = 'blue';
      break;
    case 'completed':
      color = 'green';
      break;
    case 'cancelled':
      color = 'dark';
      break;
    case 'declined':
      color = 'red';
      break;
    case 'no-show':
      color = 'orange';
      break;
    case 'rescheduled':
      color = 'gray';
      break;
    default:
      color = 'dark';
      break;
  }
  return color;

};


/**
 * Calculates the total amount paid using the bill's payment methods and amounts
 *
 * @param {Array} payments - An array of payment objects.
 * @return {number} The total amount paid using the Paystack payment method.
 */
export const calculatePaystackAmount = ( payments ) => {
  let sum = 0;
  for ( const payment of payments ) {
    if ( payment.paymentMethod === 'card' || payment.paymentMethod === 'momo' ) {
      sum += parseFloat( payment.amount );
    }
  }
  return sum;

  // return payments?.reduce( ( total, pay ) => {
  //   if ( pay.paymentMethod === 'card' || pay.paymentMethod === 'momo' )
  //     return ( total + ( parseFloat( pay.amount ) || 0 ) );
  //   return 0;
  // }, 0 );

};


export const configurePaystack = ( amount, customer, metadata ) => {
  let config = {
    publicKey: process.env.REACT_APP_PAYMENT_PUBLIC_KEY,
    email: customer?.email || 'admin@riviaco.com',
    amount: amount * 100,
    firstname: customer?.firstName || '',
    lastname: customer?.lastName || '',
    phone: customer?.contact || '',
    metadata: metadata || {},
    currency: 'GHS',
    channels: [ 'mobile_money', 'bank', 'card', 'qr', 'ussd', 'eft', 'bank_transfer', 'payattitude' ],
    // reference?: string
  };

  return config;
};


export const revenueAggregator = ( dates = [], dateField ) => {
  let cleanedDates = [];

  dates?.forEach( dt => {
    let formattedDate = new Date( dt[ dateField ] ).toDateString();
    const fndDate = cleanedDates.find( cd => new Date( cd.date ).toDateString() == formattedDate );

    if ( fndDate )
      cleanedDates.map( cd => {
        if ( new Date( cd.date ).toDateString() === formattedDate ) {
          cd.sum = ( cd.sum || 0 ) + ( dt.payments?.reduce( ( ( acc, cur ) => acc + cur?.amount ), 0 ) || 0 );
          return cd;
        }
        else
          return cd;
      } );
    else
      cleanedDates.push( {
        date: formattedDate,
        sum: ( dt?.payments?.reduce( ( ( acc, cur ) => acc + cur?.amount ), 0 ) || 0 )
      } );

  } );

  return cleanedDates;
};


export const consultationChartDataProcessor = ( consultationsData, dataLength = 7, endWithCurrentDate = true ) => {

  // group and count occurrences by date
  let countedDates =
    dateCounter( consultationsData, 'createdAt' )
      .sort( ( a, b ) => new Date( a.date ) - new Date( b.date ) );

  if ( countedDates.length > dataLength )
    countedDates = countedDates.slice( countedDates.length - dataLength, countedDates.length );

  // generate a list of dates between the first and last dates of the counted dates
  let dateRange =
    getDatesBetween(
      new Date( countedDates[ 0 ]?.date ),
      endWithCurrentDate ? new Date() :
        new Date( countedDates[ countedDates.length - 1 ]?.date ) );

  if ( dateRange.length > dataLength )
    dateRange = dateRange.slice( dateRange.length - dataLength, dateRange.length );

  // update the generated list with counted occurrences and set the rest to 0
  const data = dateRange.map( d => {
    const matchedDates = countedDates.find(
      cd => new Date( cd.date ).toDateString() === new Date( d ).toDateString() );

    if ( matchedDates )
      return {
        date: d,
        count: matchedDates.count
      };
    else
      return {
        date: d,
        count: 0
      };
  } );

  return {
    data,
    countedDates
  };
};



export const revenueChartDataProcessor = ( revenueData, dataLength = 7, endWithCurrentDate = true ) => {

  // group and count occurrences by date
  let summedRevenue =
    revenueAggregator( revenueData, 'createdAt' )
      .sort( ( a, b ) => new Date( a.date ) - new Date( b.date ) );

  if ( summedRevenue.length > dataLength )
    summedRevenue = summedRevenue.slice( summedRevenue.length - dataLength, summedRevenue.length );

  // generate a list of dates between the first and last dates of the counted dates
  let dateRange =
    getDatesBetween(
      new Date( summedRevenue[ 0 ]?.date ),
      endWithCurrentDate ? new Date() :
        new Date( summedRevenue[ summedRevenue.length - 1 ]?.date ) );

  if ( dateRange.length > dataLength )
    dateRange = dateRange.slice( dateRange.length - dataLength, dateRange.length );

  // update the generated list with counted occurrences and set the rest to 0
  const data = dateRange.map( d => {
    const matchedDates =
      summedRevenue.find( cd => new Date( cd.date ).toDateString() === new Date( d ).toDateString() );

    if ( matchedDates )
      return {
        date: d,
        sum: matchedDates.sum
      };
    else return {
      date: d,
      sum: 0
    };
  } );


  return {
    data,
    summedRevenue
  };

};

// export const expensesChartDataProcessor = ( expenses, dataLength = 7, endWithCurrentDate ) => {

//   // group and count occurrences by date
//   let countedDates =
//     dateCounter( expenses, 'createdAt' )
//       .sort( ( a, b ) => new Date( a.date ) - new Date( b.date ) );

//   if ( countedDates.length > dataLength )
//     countedDates = countedDates.slice( countedDates.length - dataLength, countedDates.length );

//   // generate a list of dates between the first and last dates of the counted dates
//   let dateRange =
//     getDatesBetween(
//       new Date( countedDates[ 0 ]?.date ),
//       endWithCurrentDate ? new Date() :
//         new Date( countedDates[ countedDates.length - 1 ]?.date ) );

//   if ( dateRange.length > dataLength )
//     dateRange = dateRange.slice( dateRange.length - dataLength, dateRange.length );

//   // update the generated list with counted occurrences and set the rest to 0
//   const data = dateRange.map( d => {
//     const matchedDates = countedDates.find(
//       cd => new Date( cd.date ).toDateString() === new Date( d ).toDateString() );

//     if ( matchedDates )
//       return {
//         date: d,
//         count: matchedDates.count
//       };
//     else return {
//       date: d,
//       count: 0
//     };
//   } );

//   return {
//     data,
//     countedDates
//   };

// };